const companyInfo = {
  name: {
    full: "ADYSUN VENTURES PVT. LTD.",
    short: "ADYSUN VENTURES",
    legal: "ADYSUN VENTURES PRIVATE LIMITED",
  },
  contact: {
    emails: [
      {
        type: "primary",
        address: "info@adysunventures.com",
        department: "General Inquiries",
      },
      //   {
      //     type: "support",
      //     address: "support@adysunventures.com",
      //     department: "Technical Support",
      //   },
      {
        type: "careers",
        address: "hr@adysunventures.com",
        department: "HR & Recruitment",
      },
    ],
    phones: [
      {
        type: "mobile",
        number: "+91 9420245878",
        department: "Mobile 1",
      },
      //   {
      //     type: "mobile2",
      //     number: "+91 9420245878",
      //     department: "Mobile 2",
      //   },
    ],
    locations: [
      {
        id: "location-1",
        type: "headquarters",
        name: "Location 1",
        address: {
          line1: "A2-704, Apramey CHSL",
          line2: "Kanchanpushp Complex, Ghodbundar Road",
          area: "Kavesar",
          city: "Thane West",
          state: "Maharashtra",
          pincode: "400615",
          country: "India",
        },
        coordinates: {
          lat: 19.25791113539065,
          lng: 72.966231824157,
        },
        googleMapsUrl:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.562008566088!2d72.966231824157!3d19.25791113539065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bb9575b6d513%3A0x45cf3b57fef76be9!2sKanchanpushp%20Complex%2C%20KANCHAN%20PUSHP%20SOCIETY%2C%20Kavesar%2C%20Thane%20West%2C%20Thane%2C%20Maharashtra%20400607!5e0!3m2!1sen!2sin!4v1734870578839!5m2!1sen!2sin",
      },
      {
        id: "location-2",
        type: "branch",
        name: "Location 2",
        address: {
          line1: "Off 2nd Floor, Surekha Apt",
          line2: "Near Dena Bank, Opp Panchami Hotel",
          area: "Swargate",
          street: "Pune-Satara Road",
          city: "Pune",
          state: "Maharashtra",
          pincode: "411037",
          country: "India",
          landmarks: ["Near Dena Bank", "Opposite Panchami Hotel"],
        },
      },
    ],
  },
  social: {
    platforms: [
    //   {
    //     name: "Facebook",
    //     url: "#",
    //     icon: "fab fa-facebook-f",
    //     isActive: true,
    //   },
      {
        name: "Twitter",
        url: "https://x.com/adysunventures",
        icon: "fab fa-twitter",
        isActive: true,
      },
      {
        name: "Instagram",
        url: "https://www.instagram.com/adysunventures/",
        icon: "fab fa-instagram",
        isActive: true,
      },
    //   {
    //     name: "YouTube",
    //     url: "#",
    //     icon: "fab fa-youtube",
    //     isActive: true,
    //   },
      {
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/adysun-ventures/",
        icon: "fab fa-linkedin-in",
        isActive: true,
      },
    ],
  },
  meta: {
    title:
      "Adysun Ventures | IT Solutions & Business Strategy Experts | Tailored IT Services",
    description:
      "Adysun Ventures, founded in Dec 2020, is a premier IT service provider specializing in business strategies and cutting-edge technology. Partner with us for reliable and innovative IT solutions.",
    keywords:
      "Adysun Ventures, IT Solutions, Business Strategies, IT Services, Premium IT Products, Collaborative IT Partnerships, Innovative IT Solutions",
    ogImage: "https://adysunventures.com/assets/adysun_ventures_image.jpg",
    foundedYear: 2020,
  },
  stats: {
    totalProjects: 50,
    happyClients: 200,
    awards: 3,
  },
  about: {
    shortDescription:
      "We deliver high-performance services to help your business embrace innovation and tackle the ever-changing challenges of today's digital world. Designed to meet your specific needs, our services capture and deliver business value in a cost-effective way. Based on your strategic objectives, we focus on business outcomes in software engineering, advanced technology, development teams, digital consulting, and solution operations.",
  },
  website: {
    domain: "adysunventures.com",
    url: "https://adysunventures.com",
  },
};

export default companyInfo; 